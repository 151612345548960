import React, { useState } from 'react'
import { version, validate } from 'uuid'
import {
  DocumentIcon,
  FolderIcon,
  FolderOpenIcon,
  PlayIcon
} from '@heroicons/react/outline'

import {
  ExclamationCircleIcon
} from '@heroicons/react/solid'


const OFFSET = 16

export default function TreeNode({ node, level = 0, currentPath, setCurrentPath, project, test = false, exlamination = false }) {
  // console.log("test trnode", test)
  const [collapsed, setCollapsed] = useState(true)
  const iconClass = 'stroke-1 cursor-pointer basis-[20px] flex-shrink-0'
  return (
    <div className='text-coolGray-400'>
      <div className={'flex gap-1 cursor-pointer items-center hover:bg-[#39414A]' + (currentPath?.path === node?.path ? ' bg-[#39414A] border border-blueGray-100 rounded' : '')}
        onClick={() => {
          setCollapsed(prev => !prev)
          setCurrentPath(node)
        }}
        style={{ paddingLeft: 8 + (level || 0) * OFFSET + 'px' }}>

        {!node.hasOwnProperty('children')
          ?
          <DocumentIcon className={`${iconClass} text-sky-400`} />
          :
          !collapsed
            ?
            <FolderOpenIcon className={`${iconClass} text-yellow-400`} />
            :
            <FolderIcon className={`${iconClass} text-yellow-400`} />
        }

        {validate(node.name) && version(node.name) === 4 && project?.name ? project.name : node.name}


        {/* {validate(node.name) && version(node.name) === 4 && project?.name
          ?
          project.name.length > 14
            ?
            project.name.substr(0, 14) + "..."
            :
            project.name
          :
          node.name.length > 14
            ?
            node.name.substr(0, 14) + "..."
            :
            node.name} */}


        {test && !node.hasOwnProperty('children')
          ?
          <div className='flex absolute left-[250px]'>
            {/* <ExclamationCircleIcon className=' w-[20px] h-[20px] text-[#F9DF8F] ml-0.5' /> */}
            <PlayIcon className=' w-[16px] h-[16px] text-gray-500 cursor-pointer hover:text-gray-400 ml-0.5' />
          </div>
          :
          <></>}
        {exlamination && !node.hasOwnProperty('children')
          ?
          <div className='flex absolute left-[250px]'>
            <ExclamationCircleIcon className=' w-[16px] h-[16px] text-[#F9DF8F] ml-0.5' />
            {/* <PlayIcon className=' w-[20px] h-[20px] text-gray-300 cursor-pointer hover:text-gray-400 ml-0.5' /> */}
          </div>
          :
          <></>}

      </div>
      {!!node.children && !!node.children.length && !collapsed &&
        <div className="flex flex-col gap-1">
          {node.children.sort((a, b) => (a.children && b.children) || (!a.children && !b.children) ? 0 : a.children && !b.children ? -1 : 1).map((item, index) => {
            return <TreeNode
              key={index} node={item} level={level + 1} currentPath={currentPath} setCurrentPath={setCurrentPath} test={test} exlamination={exlamination}
            />
          })}
        </div>
      }
    </div>
  )
}