import React, { useEffect, useState, useRef } from 'react'
import {
  LightningBoltIcon,
  XCircleIcon
} from '@heroicons/react/outline'
import { JSHINT as jshint } from 'jshint'
import { Terminal } from 'xterm'
import 'xterm/css/xterm.css'
import io from 'socket.io-client'
import { FitAddon } from 'xterm-addon-fit'
import { useHttp } from '../../hooks/http.hook'
import { useSelector } from 'react-redux'



// wss://wss.deepdev.space
const socket = io("", {
 'force new connection': true,
 reconnectionAttempts: 'Infinity',
 timeout: 10000,
 transports: ['websocket'],
})


export const Problem = ({ codeForCheck, languageName, path }) => {
  const [problems, setProblems] = useState([])
  const [bottomBar, setBottomBar] = useState('problems')
  const [terminalBuffer, setTerminalBuffer] = useState('')
  const terminalRef = useRef(null)
  const divRef = useRef(null)
  const termRef = useRef(null)
  const [codeprompt, setCodeprompt] = useState('')
  const { loading, request, error, clearError } = useHttp()
  const auth = useSelector(state => state.auth)
  
  

  useEffect(() => {
    checkMistakes(codeForCheck)
  }, [codeForCheck])
  
  
  
  
  
  
  
   useEffect(() => {
    const saveContent = async () => {
      try {
        const gemini = await request('/api/gemini/generateCodePrompt', 'POST', { codeForCheck }, { authorization: 'Bearer ' + auth.token })
        setCodeprompt(gemini.gemini.text)
      } catch (error) {
        // обработка ошибки
        console.error(error)
      }
    }

    const timer = setTimeout(() => {
      saveContent()
    }, 5000)

    return () => clearTimeout(timer)
  }, [codeForCheck])
  
  
  
  

  const checkErrors = (value) => {
    jshint(value.split('\n'))
    return jshint.data().errors ? jshint.data().errors : []
  }



  useEffect(() => {
    const match = path?.path?.match(/\\([^\\]+)\\/);
    const result = match ? match[1] : ''; // Обрабатываем случай, если match возвращает null
    socket.emit('ptyPath', result);
    if (bottomBar === 'terminal') {
      if (!termRef.current) {
        const term = new Terminal({
          rendererType: 'dom',
          convertEol: true,
          cursorBlink: true,
          cursorStyle: 'block',
          fontFamily: 'Roboto Mono, monospace',
          fontSize: 13,
          theme: {
            background: '#24292E',
            foreground: '#9CA3AF',
            cursor: '#ffffff',
          },
        })

        termRef.current = term
        const fitAddon = new FitAddon()
        term.loadAddon(fitAddon)
        term.open(divRef.current)
        fitAddon.fit()

        // Восстановление содержимого терминала
        if (terminalBuffer) {
          term.write(terminalBuffer)
        } else {
          term.write('Connected to terminal.\n')
        }

        // Обработка ввода данных с клавиатуры
        let commandBuffer = ''
        term.onData((data) => {
          if (data === '\r') {
            socket.emit('command', commandBuffer + '\n')
            term.write('\r\n')
            commandBuffer = ''
          } else if (data === '\x7f') {
            if (commandBuffer.length > 0) {
              commandBuffer = commandBuffer.slice(0, -1)
              term.write('\b \b')
            }
          } else {
            commandBuffer += data
            term.write(data)
          }
        })

        // Получение данных с сервера
        socket.on('output', (data) => {
          term.write(formatTextWithColors(data)) // Отправка отформатированного текста
          setTerminalBuffer((prev) => prev + data) // Сохранение данных в буфер
        })

        // Обработка подключения и ошибок
        socket.on('connect', () => term.write('Connected to server.\n'))
        socket.on('disconnect', () => term.write('Disconnected from server.\n'))
        socket.on('error', (error) => term.write(`Socket error: ${error}\n`))
      } else {
        termRef.current.open(divRef.current)
        const fitAddon = new FitAddon()
        termRef.current.loadAddon(fitAddon)
        fitAddon.fit()
      }
    } else if (bottomBar === 'problems') {
      if (termRef.current) {
        let bufferContent = ''
        for (let i = 0; i < termRef.current.buffer.active.length; i++) {
          const line = termRef.current.buffer.active.getLine(i)
          if (line) {
            bufferContent += line.translateToString() + '\n'
          }
        }
        setTerminalBuffer(bufferContent)
        termRef.current.dispose()
        termRef.current = null
      }
    }
  }, [bottomBar])

  // Функция для форматирования текста с разными цветами
  const formatTextWithColors = (text) => {
    return text
      // Git команды
      .replace(/(\bgit\b)/g, '\x1b[36m$1\x1b[0m')      // Циан цвет для команды `git`
      .replace(/(\bcommit\b)/g, '\x1b[32m$1\x1b[0m')    // Зеленый цвет для команды `commit`
      .replace(/(\bpush\b)/g, '\x1b[33m$1\x1b[0m')      // Желтый цвет для команды `push`
      .replace(/(\bpull\b)/g, '\x1b[34m$1\x1b[0m')      // Синий цвет для команды `pull`
      .replace(/(\bstatus\b)/g, '\x1b[35m$1\x1b[0m')    // Пурпурный цвет для команды `status`
      .replace(/(\bbranch\b)/g, '\x1b[37m$1\x1b[0m')    // Белый цвет для команды `branch`
      .replace(/(\bmerge\b)/g, '\x1b[41m$1\x1b[0m')     // Белый текст на красном фоне для команды `merge`
      .replace(/(\breset\b)/g, '\x1b[42m$1\x1b[0m')     // Белый текст на зеленом фоне для команды `reset`

      // Node.js команды
      .replace(/(\bnode\b)/g, '\x1b[36m$1\x1b[0m')      // Циан цвет для команды `node`
      .replace(/(\bnpm\b)/g, '\x1b[32m$1\x1b[0m')       // Зеленый цвет для команды `npm`
      .replace(/(\bstart\b)/g, '\x1b[33m$1\x1b[0m')     // Желтый цвет для команды `start`
      .replace(/(\binstall\b)/g, '\x1b[34m$1\x1b[0m')   // Синий цвет для команды `install`
      .replace(/(\buninstall\b)/g, '\x1b[35m$1\x1b[0m') // Пурпурный цвет для команды `uninstall`
      .replace(/(\btest\b)/g, '\x1b[37m$1\x1b[0m')      // Белый цвет для команды `test`
      .replace(/(\bupdate\b)/g, '\x1b[41m$1\x1b[0m')    // Белый текст на красном фоне для команды `update`

      // Обобщенные сообщения
      .replace(/(\berror\b)/g, '\x1b[41m$1\x1b[0m')     // Белый текст на красном фоне для `error`
      .replace(/(\bwarning\b)/g, '\x1b[43m$1\x1b[0m')   // Черный текст на желтом фоне для `warning`
      .replace(/(\bsuccess\b)/g, '\x1b[42m$1\x1b[0m')   // Белый текст на зеленом фоне для `success`

      // LINUX команды
      .replace(/(\bapt\b)/g, '\x1b[31m$1\x1b[0m')    // Красный цвет для команды `apt`
      .replace(/(\bupdate\b)/g, '\x1b[32m$1\x1b[0m')  // Зеленый цвет для команды `update`
      .replace(/(\bupgrade\b)/g, '\x1b[33m$1\x1b[0m') // Желтый цвет для команды `upgrade`
      .replace(/(\binstall\b)/g, '\x1b[34m$1\x1b[0m') // Синий цвет для команды `install`
      .replace(/(\bremove\b)/g, '\x1b[35m$1\x1b[0m')  // Пурпурный цвет для команды `remove`
      .replace(/(\bsearch\b)/g, '\x1b[36m$1\x1b[0m')  // Циан цвет для команды `search`
      .replace(/(\berror\b)/g, '\x1b[41m$1\x1b[0m')   // Белый текст на красном фоне для `error`
      .replace(/(\bwarning\b)/g, '\x1b[43m$1\x1b[0m') // Черный текст на желтом фоне для `warning`
      .replace(/(\bsuccess\b)/g, '\x1b[42m$1\x1b[0m') // Белый текст на зеленом фоне для `success`
      .replace(/(\bpermission denied\b)/g, '\x1b[31m$1\x1b[0m') // Красный цвет для сообщения о запрещении
      .replace(/(\broot\b)/g, '\x1b[35m$1\x1b[0m') // Пурпурный цвет для `root`
      .replace(/(\busername\b)/g, '\x1b[33m$1\x1b[0m') // Желтый цвет для `username`
      .replace(/(\bpath\b)/g, '\x1b[34m$1\x1b[0m')    // Синий цвет для `path`
      .replace(/(\bfile\b)/g, '\x1b[37m$1\x1b[0m')    // Белый цвет для `file`
  }



  const checkMistakes = (code) => {
    if (!code) {
      return
    }
    checkErrors(code)
    setProblems(jshint?.data()?.errors)
  }

  const handleMenuClick = (menu) => {
    setBottomBar(menu)
  }

 const listOfProblems = [
     
     
      <li >
      <div className='flex items-center px-3 py-0.5'>
        <div className='flex flex-row items-center '>
          <span className='text-[#F4C78C] font-extrabold mx-1'>AI&nbsp;&nbsp;</span>
        </div>
        <p className='text-gray-400'>
         {codeprompt}

        </p>
      </div>
    </li>,
     
     
    ...problems.map((problem, idx) => (
      <li key={idx}>
        <div className='flex items-center px-3 py-0.5'>

          {problem.code !== 'E019' ? (
            <LightningBoltIcon className='w-3 h-3 text-[#F4C78C] mx-2' />
          ) : (
            <XCircleIcon className='w-3 h-3 text-[#EC847D] mx-2' />
          )}

          <p className='text-gray-400'>
            {problem.reason} Line: {problem.line} Character: {problem.character}
          </p>
        </div>
      </li>
    ))
   
  ]

  return (
    <div>
      <div className='flex flex-col h-[200px] max-h-[200px] w-full border-t border-[#30363D]'>
        <div className='border-b border-[#30363D]'>
          <button
            className={` ${bottomBar === 'problems' ? 'font-semibold text-white' : ''} border-l border-r border-[#30363D] py-1 px-6 text-gray-400 hover:font-semibold hover:text-white`}
            onClick={() => handleMenuClick('problems')}
          >
            Проблемы
          </button>
          <button
            className={` ${bottomBar === 'terminal' ? 'font-semibold text-white' : ''} border-l border-r border-[#30363D] py-1 px-6 text-gray-400 hover:font-semibold hover:text-white`}
            onClick={() => handleMenuClick('terminal')}
          >
            Терминал
          </button>
        </div>

        {bottomBar === 'problems' && languageName === 'JavaScript' ? (
          <div className='h-full overflow-auto scrollbar-hide'>
            <ul>{listOfProblems}</ul>
          </div>
        ) : (
          <div
        style={{ width: "100%", height: "170px", zIndex: 100 }}
            ref={divRef} />
        )}
      </div>
    </div>
  )
}
