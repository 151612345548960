import React, { useEffect, useState } from 'react'
import { Switch } from 'antd';
import { useSelector } from 'react-redux'
import {
  TrashIcon,
} from '@heroicons/react/outline'
import { useHttp } from '../../hooks/http.hook'



export const Member = ({ usersOnline, showModal, color, name, you, whoAdmin, room, projectUsersPermission, socket }) => {
  const { request } = useHttp()
  const auth = useSelector(state => state.auth)
  const [switchPerm, setSwitchPerm] = useState(null)


  useEffect(() => {
    setSwitchPerm(projectUsersPermission.find(el => el.user === name)?.permission)
  }, [projectUsersPermission])

  useEffect(() => {
    try {
      (async () => {
        const user = await request('/api/project/changePermission', 'POST', { projectId: room, name, permission: switchPerm }, { authorization: 'Bearer ' + auth.token })
      })()
    } catch (e) {
      console.log(e)
    }
  }, [switchPerm])


  useEffect(() => {
    socket?.on("changePermissionOnClient", userPermissionInRoom => {
      console.log("IN MEMBER PERMISSION--", userPermissionInRoom)

      if (userPermissionInRoom.user === name) {
        setSwitchPerm(userPermissionInRoom.permission)
      }

    })
  }, [socket])

  const RWTrash = () => {
    const isAdmin = whoAdmin === you
    const isYou = you === name

    return (
      <>
        {
          isAdmin && !isYou ?
            <Switch onChange={() => {
              let userPermissionInRoom = {
                user: name,
                permission: !switchPerm,
                roomId: room
              }

              socket.emit("changePermission", userPermissionInRoom);
              setSwitchPerm(prev => !prev)
            }} checked={switchPerm} className='bg-gray-700 rounded-md text-xs w-12 text-gray-400' checkedChildren="RW" unCheckedChildren="R" />

            :
            isAdmin || whoAdmin === name || switchPerm ?
              <div className='flex items-center justify-center bg-gray-700 rounded-md text-xs w-7 h-6 text-gray-400'>RW</div>
              :
              <div className='flex items-center justify-center bg-gray-700 rounded-md text-xs w-7 h-6 text-gray-400'>R</div>
        }
        {
          isAdmin && whoAdmin !== name ?
            <TrashIcon className={`h-[16px] text-gray-700 cursor-pointer hover:text-gray-400 ml-1`} onClick={
              () => {
                showModal({
                  currentTarget: { id: "deleteMember" },
                  name
                })
              }} />
            :
            <div className='h-10 w-10' />

        }
      </>
    )
  }

  return (
    <div className='flex items-center gap-2'>
      <div className='w-1 h-8 order-1' style={{ background: color }}></div>
      <div className="rounded-full w-2 h-2 order-2" style={usersOnline.find(userOnline => userOnline.name === name) !== undefined ? { background: "#65A30D" } : { background: "#A3A3A3" }}></div>
      <span className='text-gray-100 order-3' >&nbsp; {you === name ? "Вы" : name} ({usersOnline.find(userOnline => userOnline.name === name) !== undefined ? "В сети" : "Не в сети"})   </span>
      <span className='flex items-center absolute right-1 order-4'>
        <RWTrash />
      </span>
    </div>
  )
}

