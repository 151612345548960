import * as React from "react"
import { LeftMain } from '../components/Sidebar/LeftMain'

const HScreenLayout = (props) => {
  return (
    <div className="h-screen w-full bg-[#1f2428]">
      <div className="flex h-screen">
        <LeftMain />
        <div className="flex-1 flex flex-col w-full">
          <div className="flex-1 w-full h-full">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HScreenLayout