import {
  HomeIcon,
  CogIcon,
  ShareIcon,
  SearchIcon,
  LightningBoltIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline'
import { Divider, Popover } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout, setLSidebar } from '../../redux/actions/auth.actions'

export const LeftMain = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const history = useNavigate()


  // const settingsTitle = (<div className='bg-amber-300'>SOME TEXT</div>)
  const settingsClickHandler = e => {
    switch (e.currentTarget.id) {
      case 'settings':
        history('/project/settings')
        break
      case 'profile':
        history('/user/profile')
        break
      case 'pass':
        history('/user/password')
        break
      case 'logout':
        dispatch(logout())
        history('/user/login')
        break
      default:
    }
  }

  const settingsContent = (
    <div className="text-sm text-blueGray-300 flex flex-col gap-2">
      {auth?.selectedProject &&
        <>
          <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="settings"
             onClick={settingsClickHandler}>Настройки проекта</p>
          <Divider className="bg-blueGray-300" style={{margin: '0'}}/>
        </>
      }
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="profile"
         onClick={settingsClickHandler}>Мой профиль</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="pass"
         onClick={settingsClickHandler}>Смена пароля</p>
      <Divider className="bg-blueGray-300" style={{ margin: '0' }}/>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="logout"
         onClick={settingsClickHandler}>Выход</p>
    </div>
  )

  return (
    <div className="flex h-[100vh]">
      <div className="w-16 bg-[#24292E] h-full p-[20px] flex flex-col items-center justify-between">
        {/*top side*/}
        <div className="flex flex-col gap-5 text-blueGray-300">
          <HomeIcon className="w-full stroke-1 hover:stroke-2 cursor-pointer" onClick={() => {history('/')}}/>
          {auth?.selectedProject &&
            <>
              <DocumentDuplicateIcon
                className={`w-full ${auth?.lSidebar === 'filemanger' ? 'stroke-2' : 'stroke-1'} hover:stroke-2 cursor-pointer`}
                onClick={() => {
                  auth?.lSidebar !== 'filemanger' ? dispatch(setLSidebar('filemanger')) : dispatch(setLSidebar(null))
                }}
              />
              <SearchIcon
                className={`w-full ${auth?.lSidebar === 'search' ? 'stroke-2' : 'stroke-1'} hover:stroke-2 cursor-pointer`}
                onClick={() => {
                  auth?.lSidebar !== 'search' ? dispatch(setLSidebar('search')) : dispatch(setLSidebar(null))
                }}
              />
              <ShareIcon
                className={`w-full ${auth?.lSidebar === 'git' ? 'stroke-2' : 'stroke-1'} hover:stroke-2 cursor-pointer`}
                onClick={() => {
                  auth?.lSidebar !== 'git' ? dispatch(setLSidebar('git')) : dispatch(setLSidebar(null))
                }}
              />
              <LightningBoltIcon
                className={`w-full ${auth?.lSidebar === 'test' ? 'stroke-2' : 'stroke-1'} hover:stroke-2 cursor-pointer`}
                onClick={() => {
                  auth?.lSidebar !== 'test' ? dispatch(setLSidebar('test')) : dispatch(setLSidebar(null))
                }}
              />
            </>
          }
        </div>

        {/*bottom side*/}
        <Popover
          placement="rightBottom"
          // title={'text title'}
          content={settingsContent}
          trigger="click"
        >
          <CogIcon className="block w-full stroke-1 hover:stroke-2 text-blueGray-300 cursor-pointer"/>
        </Popover>
      </div>
    </div>
  )
}