import {Routes, Route, Navigate} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { checkLogged } from './redux/actions/auth.actions'
import { LoaderFull } from './components/Loader/LoaderFull'
import { UserProfilePage } from './pages/user/UserProfilePage'
import { UserPasswordPage } from './pages/user/UserPasswordPage'
import { AuthPage } from './pages/user/AuthPage'
import { NotFound } from './pages/NotFound'
import { RequireAuth } from './hoc/RequireAuth'
import { Main } from './pages/Main'
import { SettingsPage } from './pages/project/Settings'
import { ProjectPage } from './pages/project/Main'


function App() {
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    // check local storage and load to store
    dispatch(checkLogged()).then(res => {
      setChecking(false)
    })
  }, [])

  if (checking) return <LoaderFull />

  return (
    <>
      <ToastContainer hideProgressBar />

      <Routes>
        <Route path="/user/register" element={<AuthPage isLogin={false} />} />
        <Route path="/user/login" element={<AuthPage />} />

        {/*AUTH NEED Start*/}
        <Route path="/" element={<Navigate to="/main" replace />} />

        <Route path="/main" element={
          <RequireAuth>
            <Main />
          </RequireAuth>
        } />

        <Route path="/user" element={<Navigate to="/user/profile" replace />} />
        <Route path="/user/profile" element={
          <RequireAuth>
            <UserProfilePage />
          </RequireAuth>
        } />

        <Route path="/user/password" element={
          <RequireAuth>
            <UserPasswordPage />
          </RequireAuth>
        } />

        <Route path="/project/settings" element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        } />

        <Route path="/project/main" element={
          <RequireAuth>
            <ProjectPage />
          </RequireAuth>
        } />

        {/*<Route path="/docs" element={*/}
        {/*  <RequireAuth>*/}
        {/*    /!*<Docs />*!/*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*<Route path="/test" element={<Navigate to="/test/dashboard" replace />} />*/}
        {/*<Route path="/test/dashboard" element={*/}
        {/*  <RequireAuth>*/}
        {/*    /!*<Tests />*!/*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*<Route path="/test/cases" element={*/}
        {/*  <RequireAuth>*/}
        {/*    /!*<Cases />*!/*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*<Route path="/test/integration" element={*/}
        {/*  <RequireAuth>*/}
        {/*    /!*<Settings />*!/*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*<Route path="/test/apps" element={*/}
        {/*  <RequireAuth>*/}
        {/*    /!*<Apps />*!/*/}
        {/*  </RequireAuth>*/}
        {/*} />*/}

        {/*AUTH NEED End*/}

        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  )
}
export default App;
