import React, { useEffect, useState } from 'react'
import { ChatInput } from './ChatInput'
import { ChatMessage } from './ChatMessage'
import { useHttp } from '../../hooks/http.hook'
import { useSelector } from 'react-redux'
import io from 'socket.io-client';

let socket

process.env.NODE_ENV === 'development' 
?
socket = io("http://localhost:5000", {
  transports: ["websocket", "polling"]
})
:
socket = io("wss://wss.deepdev.space", {
 'force new connection': true,
 reconnectionAttempts: 'Infinity',
 timeout: 10000,
 transports: ['websocket'],
})



export const Chat = ({ setUsersOnline, setSocket, ...props }) => {
  const [allMessages, setAllMessages] = useState([])
  const [usersInRoomChat, setUsersInRoomChat] = useState([])
  const auth = useSelector(state => state.auth)
  const { request } = useHttp()

  setSocket(socket)
  useEffect(() => {
    getMessages()

    const userConnectionToRoom = {
      name: props?.you,
      room: props?.project?.id
    };
    socket.emit("userConnectionToRoom", userConnectionToRoom);

    socket.on("message", message => {
      console.log("msg-", message)
      setAllMessages(prev => ([...prev, message]));
      console.log("MESEGES FROM IO -", allMessages)
    });

    socket.on("usersInRoom", users => {
      console.log("IN ROOM--", users)
      setUsersOnline(users)
      setUsersInRoomChat(users)
    })

    return () => {
      socket.emit("userDisconnecting", props.project.id)
    }
  }, []);


  const sendMessage = async (room, color, name, time, message) => {
    try {
      const msg = await request('/api/chat/sendMessage', 'POST', { projectId: room, color, name, time, message }, { authorization: 'Bearer ' + auth.token })
    } catch (e) {
      console.log(e)
    }
  }

  const getMessages = async () => {
    try {
      const allMsg = await request('/api/chat/getMessages', 'POST', { projectId: props.project.id }, { authorization: 'Bearer ' + auth.token })
      setAllMessages(allMsg.msgArr)
    } catch (e) {
      console.log(e)
    }
  }

  const addMessage = (message) => {
    // let messageArr = [...allMessages]
    // messageArr.push(message)
    // setAllMessages(prev => ([...prev, message]))
    sendMessage(message.room, message.color, message.name, message.time, message.message)
  }

  const submitMessage = messageString => {
    let now = new Date();
    const message = { room: props.project.id, color: props.color, name: props.you, time: `${now.getHours()}:${now.getMinutes()}`, message: messageString }
    if (messageString) {
      socket.emit("send", message);
      addMessage(message)
    }
  }

  const listOfMessages = allMessages.map((message, index) =>
    <li key={index}>
      <ChatMessage
        usersInRoomChat={usersInRoomChat}
        color={props.color}
        time={message.time}
        message={message.message}
        name={message.name}
        you={props.you}
      />
    </li>

  );

  return (
    <div>
      <div className='w-full h-full' >
        <ul className="text-gray-100 mb-5" >{listOfMessages}</ul>
      </div>
      <div className="absolute bottom-0">
        <ChatInput
          onSubmitMessage={messageString => submitMessage(messageString)}
        />

      </div>


    </div>
  )
}


