import { useNavigate } from 'react-router-dom'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="h-[100vh] w-[100vw] flex flex-col gap-4 justify-center items-center bg-[#101214]">
      <h1 className='text-5xl text-white'>404, нет такой странички.</h1>
      <button className="text-3xl text-white" onClick={() => {navigate(-1)}}>Вернуться</button>
    </div>
  )
}
