import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../redux/actions/auth.actions'
import HScreen from '../../layout/hScreen'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import { PageTitle } from '../../components/Title/pageTitle'
import Input from '../../components/Input'
import { Button } from '../../components/Button'
import { CirclePicker } from 'react-color'

export const UserProfilePage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    id: '',
    email: '',
    name: '',
    color: '',
    // img: ''
  })
  const [show, setShow] = useState(false)
  const message = useMessage()
  const history = useNavigate()

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  useEffect(() => {
    try {
      if (auth.user) {
        setForm({
          id: auth.user.id,
          email: auth.user.email,
          name: auth.user.name,
          color: auth.user.color,
          // img: auth.user.photo ? `${auth.user.photo}?v=${Date.now()}` : `/assets/userImg/no.png`
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [auth])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    try {
      const user = { ...auth.user, ...form }
      dispatch(login({ token: auth.token, user }))
      const data = await request('/api/auth/updateProfile', 'POST', { form }, { authorization: 'Bearer ' + auth.token })

      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }

  if (!auth.user) {
    return <LoaderFull/>
  }
  return (
    <HScreen>
      <div className="container mt-[75px]">
        <div className="flex flex-col gap-14">
          {/*Title*/}
          <PageTitle title="Настройки пользователя" subTitle="Цвет пользователя"/>

          <div className="flex flex-col gap-6 max-w-1/3">
            {/*Form*/}
            <div className="flex flex-col gap-1 relative">
              <span className="text-sm text-white noselect">Цвет пользователя</span>
              <div className="rounded-full w-[72px] h-[72px] border border-blueGray-300 cursor-pointer"
                   style={{ background: form.color || 'white' }} onClick={() => {setShow(prev => !prev)}}/>
              {show &&
                <div className="absolute right-0 top-0 z-10">
                  <CirclePicker onChangeComplete={(color) => {setForm(p => ({...p, color: color.hex}))}}/>
                </div>
              }
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm text-white noselect">Электронная почта</span>
              <Input
                className="bg-coolGray-600 text-blueGray-300"
                id="email"
                name="email"
                value={form.email}
                autoComplete="off"
                onChange={changeHandler}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm text-white noselect">Имя пользователя <span className="text-xs text-[#A7A7A7]">- Никнейм или имя</span></span>
              <Input
                className="bg-coolGray-600 text-blueGray-300"
                id="name"
                name="name"
                value={form.name}
                onChange={changeHandler}
              />
            </div>

            <div>
              <Button
                size="md"
                className="min-w-[150px]"
                onClick={updateHandler}
                disabled={loading}
              >
                Обновить
              </Button>
            </div>

          </div>
        </div>
      </div>
    </HScreen>
  )
}
