import React, { useEffect, useState } from 'react'
import HScreen from '../layout/hScreen'
import { PageTitle } from '../components/Title/pageTitle'
import { Modal } from 'antd'
import Input from '../components/Input'
import { Button } from '../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useMessage } from '../hooks/message.hook'
import { useHttp } from '../hooks/http.hook'
import { useNavigate } from 'react-router-dom'
import { setCurrentProject, updateProjectsList } from '../redux/actions/auth.actions'

export const Main = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const message = useMessage()
  const { loading, request, error, clearError } = useHttp()
  const navigate = useNavigate()
  const [showM, setShowM] = useState(false)
  
  const [modal, setModal] = useState({
    title: '',
    placeholder: '',
    ok: '',
    cancel: '',
    action: '',
  })
  const [project, setProject] = useState({
    name: ''
  })

  useEffect(() => {
    if (auth?.token) {
      (async () => {
        try {
          const data = await request('/api/project/getUserProjects', 'POST', { email: auth?.user?.email }, { authorization: 'Bearer ' + auth.token })
          // setUserProjects(data.projects.projects)
          dispatch(updateProjectsList(data.projects.projects))
        } catch (e) {
          console.log(e)
        }
      })();
    }
  }, [auth.token])



  useEffect(() => {
    if (auth?.selectedProject) dispatch(setCurrentProject(null))
  }, [])


  const changeHandler = event => {
    setProject({ ...project, [event.target.name]: event.target.value })
  }

  const handleOk = async e => {
    const addProject = (res) => {
      let projects = JSON.parse(JSON.stringify(auth.user.projects))
      projects.push(res.project)
      dispatch(updateProjectsList(projects))
      dispatch(setCurrentProject(res.project.id))
      navigate('/project/main')
    }

    switch (e.currentTarget.id) {
      case 'create':
        try {
          const data = await request('/api/project/createProject', 'POST', { name: project.name, userId: auth.user.id, admin: auth.user.email }, { authorization: 'Bearer ' + auth.token })
          addProject(data)
        } catch (e) {
          console.log(e)
        }
        break
      case 'clone':
        try {
          const data = await request('/api/project/cloneProject', 'POST', { gitUrl: project.name, userId: auth.user.id, admin: auth.user.email }, { authorization: 'Bearer ' + auth.token })
          addProject(data)
        } catch (e) {
          console.log(e)
        }
        break
      default:
    }
    handleCancel()
  }

  const handleCancel = () => {
    setModal({
      title: '',
      placeholder: '',
      ok: '',
      cancel: '',
      action: '',
    })
    setShowM(false)
    setProject({ name: '' })
  }

  const showModal = e => {
    switch (e.currentTarget.id) {
      case 'create':
        setModal({
          title: 'Создание нового проекта',
          placeholder: 'Введите название',
          ok: 'Создать',
          cancel: 'Отмена',
          action: 'create',
        })
        break
      case 'clone':
        setModal({
          title: 'Клонирование GIT репозитория',
          placeholder: 'URL GIT репозитория',
          ok: 'Клонировать',
          cancel: 'Отмена',
          action: 'clone',
        })
        break
      default:
    }
    setShowM(true)
  }



  return (
    <HScreen>
      <div className="container mt-[75px]">
        <div className="flex flex-col gap-14">
          {/*Title*/}
       <PageTitle title="AFIR интегрированная среда разработки" subTitle="новый способ разработки кода" />

          {/*actios*/}
          <div>
            <p className="text-xl text-coolGray-300 tracking-widest font-light leading-[150%] noselect mb-3">Начать</p>
            <p
              className="text-xl text-coolGray-300 tracking-widest font-light leading-[150%] mb-1 text-menu-blue cursor-pointer noselect"
              id="create" onClick={showModal}>Создать проект</p>
            <p
              className="text-xl text-coolGray-300 tracking-widest font-light leading-[150%] mb-1 text-menu-blue cursor-pointer noselect"
              id="clone" onClick={showModal}>Клонировать GIT репозиторий</p>
          </div>

          {/*projects*/}
          {auth?.user?.projects?.length ?
            <div className='overflow-y-auto'>
              <p className="text-xl text-coolGray-300 tracking-widest font-light leading-[150%] noselect mb-3">Ваши проекты</p>
              {auth?.user?.projects.map(prj => (
                <p
                  key={prj.id}
                  className="text-xl text-coolGray-300 tracking-widest font-light leading-[150%] mb-1 text-menu-blue cursor-pointer noselect"
                  onClick={() => {
                    dispatch(setCurrentProject(prj.id))
                    navigate('/project/main')
                  }}
                >
                  {prj.name}
                </p>
              ))}
            </div>
            : <></>
          }
        </div>
      </div>

      <Modal
        open={showM}
        title={null}
        footer={null}
        // centered
        onCancel={handleCancel}
      >
        <div className='flex flex-col gap-5'>
          <p className="text-2xl block text-blueGray-300 mb-2">{modal.title}</p>
          <Input
            className="bg-coolGray-600 text-blueGray-300"
            placeholder={modal.placeholder}
            id="name"
            name="name"
            value={project.name}
            autoComplete="off"
            onChange={changeHandler}
          />
          <div className='flex justify-between items-center gap-4'>
            <Button
              id={modal.action}
              size="md"
              className="w-full"
              onClick={handleOk}
            >
              {modal.ok}
            </Button>
            <Button
              size="md"
              color="grey"
              className="w-full"
              onClick={handleCancel}
            >
              {modal.cancel}
            </Button>
          </div>
        </div>
      </Modal>

    </HScreen>
  )
}