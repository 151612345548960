import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { Input } from 'antd';




export const Search = ({ setTreeFromSearch }) => {

    const auth = useSelector(state => state.auth)
    const project = useSelector(state => state.auth.user.projects.find(el => +el.id === +auth.selectedProject))
    const { request } = useHttp()
    const { Search } = Input;

    const onSearch = async (value) => {
        try {
            const { files, tree: tr } = await request('/api/project/findFiles', 'POST', { roomId: project.roomId, pattern: value }, { authorization: 'Bearer ' + auth.token })
            console.log('=====', files, tr)
            setTreeFromSearch(tr)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className=''>

            <Search 
                placeholder="Введите поисковый запрос"
                allowClear
                onSearch={onSearch}
                style={{
                    width: 200,
                }}
            />

        </div>
    )
}

