import React from 'react'

export const PageTitle = ({title, subTitle = ''}) => {
  return (
    <div>
      <p className='text-4xl text-coolGray-300 tracking-widest leading-[150%] font-light noselect'>{title}</p>
      {subTitle &&
        <p className='text-2xl text-coolGray-500 tracking-widest font-light noselect'>{subTitle}</p>
      }
    </div>
  )
}