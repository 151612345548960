import React, { useEffect, useState } from 'react'
import {
    DocumentTextIcon,
    XIcon
} from '@heroicons/react/outline'


export const Tabs = ({ tabList, setTabList, onTabClick, usersInTab, tabActive }) => {

    const [selectedTab, setSelectedTab] = useState({})


    useEffect(() => {
        if (tabList.length) {
            setSelectedTab(tabList[tabList.length - 1])
            onTabClick(tabList[tabList.length - 1])
        }
    }, [tabList])

    
    useEffect(() => {
            setSelectedTab(tabActive)
    }, [tabActive])

    const listOfUsersIcons = usersInTab.map((user, idx) =>
        <li key={idx} className={`ml-[-8px] z`}>
            <div className='rounded-full w-[16px] h-[16px] text-[11px] text-[#000] flex justify-center items-center' style={{ background: user.user.color }}>{user.user.name[0]}</div>
        </li>
    )

    const listOfTabs = tabList.map((tab, idx) =>
        <li key={idx} className={selectedTab.name === tab.name ? 'text-[#CBD5E1] font-semibold' : 'text-[#94A3B8] font-normal'}>

            <div className='flex items-center justify-between w-[148px] h-[28px] px-[8px] py-[4px] gap-1 border-l border-r border-[#30363D] cursor-pointer'
                onClick={() => {
                    console.log("tab", tab)
                    setSelectedTab(tab)
                    onTabClick(tab)
                }}>

                <div className='flex items-center justify-center'>
                    <DocumentTextIcon className='w-[16px] h-[16px] ' />
                    {tab.name.length < 7 ? tab.name : tab.name.substr(0, 7) + "..."}
                </div>
                {/* usersInTab[0].user.color */}
                <div className='flex items-center'>{usersInTab.length !== 0 ? <ul className='flex md:inline-flex relative'>{listOfUsersIcons}</ul> : ""}</div>

                <div>
                    {selectedTab.name === tab.name
                        ?
                        <XIcon className='w-[16px] h-[16px] text-[#94A3B8]'
                            onClick={() => {
                                setTabList(prev => (prev.filter(el => el.name !== tab.name)))
                                console.log("last tab-", tabList[tabList.length - 1])
                            }} />
                        :
                        <XIcon className='w-[16px] h-[16px] text-[#24292E] hover:text-[#94A3B8]'
                            onClick={() => {
                                setTabList(prev => (prev.filter(el => el.name !== tab.name)))
                                console.log("last tab-", tabList[tabList.length - 1])
                            }} />
                    }

                </div>

            </div>

        </li>
    );
    // overflow-hidden
    return (
        <div className='absolute z-10 ' >
            <ul className='flex md:inline-flex '>{listOfTabs}</ul>
        </div>
    )
}


