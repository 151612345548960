import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import HScreen from '../../layout/hScreen'
import { useHttp } from '../../hooks/http.hook'
import { TreeView } from '../../components/TreeView/TreeView'
import { InlineLoader } from '../../components/Loader/InlineLoader'
import { PageTitle } from '../../components/Title/pageTitle'
import { Editor } from '../../components/Editor/Editor'
import { Member } from '../../components/Member/member'

import { Chat } from '../../components/Chat/Chat'
import { Problem } from '../../components/Problem/Problem'
import { Tabs } from '../../components/Tabs/Tabs'
import { Search } from '../../components/Search/Search'
import { Git } from '../../components/Git/Git'

import { Select } from 'antd';


import {
  ShareIcon,
  FolderAddIcon,
  DocumentAddIcon,
  RefreshIcon,
  PlayIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { Divider, Modal, Popover } from 'antd'
import InputModal from '../../components/Input'
import { Button } from '../../components/Button'
import { useMessage } from '../../hooks/message.hook'
// import { gitInit } from '../../../../controllers/git.controller'


export const ProjectPage = () => {

  const message = useMessage()
  const [languageName, setLanguageName] = useState('JavaScript')
  const [languageDescription, setLanguageDescription] = useState('text/javascript')
  const [tabSize, setTabSize] = useState(4)
  const [cursor, setCursor] = useState({ language: 0, col: 0 })
  const auth = useSelector(state => state.auth)
  const project = useSelector(state => state.auth.user.projects.find(el => +el.id === +auth.selectedProject))
  const { loading, request, error, clearError } = useHttp()
  const [tree, setTree] = useState('Пока нет файлов')
  const [treeFromSearch, setTreeFromSearch] = useState('Выполните поиск')
  const [treeFromGit, setTreeFromGit] = useState(null)
  const [treeFromTest, setTreeFromTest] = useState(null)
  const [treeFromTestCreatedFor, setTreeFromTestCreatedFor] = useState("no tests")
  const [showEditor, setShowEditor] = useState(false)
  const [fileContent, setFileContent] = useState(null)
  const [path, setPath] = useState(null)
  const [isDir, setIsDir] = useState(true)
  const [projectMember, setProjectMember] = useState([])
  const [tabList, setTabList] = useState([])

  const [usersOnline, setUsersOnline] = useState([])
  const [projectUsersPermission, setProjectUsersPermission] = useState([])
  const [codeForCheck, setCodeForCheck] = useState(null)
  const [usersInTab, setUsersInTab] = useState([])
  const [socket, setSocket] = useState(null)

  const [name, setName] = useState('')
  const [memberToDel, setMemberToDel] = useState('')
  const [showM, setShowM] = useState(false)
  const [currentBranch, setCurrentBranch] = useState(null)


  const [modal, setModal] = useState({
    addonBefore: '',
    addonAfter: '',

    title: '',
    placeholder: '',
    ok: '',
    cancel: '',
    action: '',
  })

  const createFile = async (fileName) => {
    if (!path.path) {
      message('Выберите папку!')
      return
    }
    try {
      const file = await request('/api/project/createFile', 'POST', { path: path.path, isDir: isDir, fileClick: path.name, fileName: fileName, roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      setTree(prev => ({ ...prev, ...file.tree }))
      let treeAndRoomId = {
        tree: file.tree,
        roomId: project.id
      }
      socket.emit("updateTree", treeAndRoomId);
    } catch (e) {
      console.log(e)
      // message("")
    }
  }

  const createDir = async (dirName) => {
    if (!path.path) {
      message('Выберите папку!')
      return
    }
    try {
      const dir = await request('/api/project/createFolder', 'POST', { path: path.path, isDir: isDir, dirClick: path.name, dirName: dirName, roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      setTree(prev => ({ ...prev, ...dir.tree }))

      let treeAndRoomId = {
        tree: dir.tree,
        roomId: project.id
      }
      socket.emit("updateTree", treeAndRoomId);
    } catch (e) {
      console.log(e)
      // message("")
    }
  }

  const deleteF = async (fName) => {
    try {
      const fName = await request('/api/project/deleteF', 'POST', { path: path.path, roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      setTree(prev => ({ ...prev, ...fName.tree }))
      let treeAndRoomId = {
        tree: fName.tree,
        roomId: project.id
      }
      socket.emit("updateTree", treeAndRoomId);
    } catch (e) {
      console.log(e)
      // message("")
    }
  }

  const addMember = async (member) => {
    try {
      const user = await request('/api/project/addMemberToProject', 'POST', { member: member, project: project.id }, { authorization: 'Bearer ' + auth.token })
      setProjectMember(user.members)
      message('Участник добавлен')
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! addMember')
    }
  }

  const removeMebmer = async () => {
    try {
      const user = await request('/api/project/removeMebmer', 'POST', { member: memberToDel, project: project.id }, { authorization: 'Bearer ' + auth.token })
      setProjectMember(user.members)
      message('Участник удалён')
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! removeMebmer')
    }
  }

  const getProjectMembers = async () => {
    try {
      const allMembersInProject = await request('/api/project/getProjectMembers', 'POST', { project: project.id }, { authorization: 'Bearer ' + auth.token })
      setProjectMember(allMembersInProject.members)
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! getProjectMembers')
    }
  }

  const getProjectUsersPermission = async () => {
    try {
      const getAllProjectUsersPermission = await request('/api/project/getProjectUsersPermission', 'POST', { project: project.id }, { authorization: 'Bearer ' + auth.token })
      setProjectUsersPermission(getAllProjectUsersPermission.usersPermissions)
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так!')
    }
  }

  const handleCancel = () => {
    setName('')
    setModal({
      title: '',
      placeholder: '',
      ok: '',
      cancel: '',
      action: '',
    })
    setShowM(false)
  }

  const showModal = e => {
    switch (e.currentTarget.id) {
      case 'folder':
        setModal({
          title: 'Создать папку',
          placeholder: 'Название папки',
          ok: 'Создать',
          cancel: 'Отмена',
          action: 'folder',
        })
        break
      case 'file':
        setModal({
          title: 'Создать файл',
          placeholder: 'Название файла',
          ok: 'Создать',
          cancel: 'Отмена',
          action: 'file',
        })
        break
      case 'addMember':
        setModal({
          title: 'Пригласить участника',
          placeholder: 'Электронная почта',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'addMember',
        })
        break
      case 'deleteMember':
        setModal({
          title: `Удалить участника ${e.name}`,
          placeholder: '',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'deleteMember',
        })
        setMemberToDel(e.name)
        break

      case 'gitCreateRepo':
        setModal({
          addonBefore: 'https://repoAfir@gitlab.com/repoAfir/',
          addonAfter: '.git',

          title: `Создать удалённый репозиторий в GIT.`,
          placeholder: 'Название репозитория',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'gitCreateRepo',
        })

        break


      case 'gitInit':
        setModal({
          title: `Инициализировать Git.`,
          placeholder: 'Имя коммита',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'gitInit',
        })

        break

      case 'gitCommit':
        setModal({
          title: `Создать коммит`,
          placeholder: 'Имя коммита',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'gitCommit',
        })

        break


      case 'gitPush':
        setModal({
          title: `Отправить коммит в Git.`,
          placeholder: 'Имя коммита',
          ok: 'Ок',
          cancel: 'Отмена',
          action: 'gitPush',
        })

        break

      case 'deleteF':

        if (path.name === project.roomId) {
          setModal({
            title: `Нельзя удалить папку проекта!`,
            placeholder: '',
            ok: 'Ok',
            cancel: 'Cancel',
            action: '',
          })
        } else if (path.path !== "" && path.name !== project.roomId) {
          setModal({
            title: `Удалить ${path.name}`,
            placeholder: '',
            ok: 'Ok',
            cancel: 'Cancel',
            action: 'deleteF',
          })
        }
        else {
          setModal({
            title: `Нечего удалять, выберите файл или папку!`,
            placeholder: '',
            ok: 'Ok',
            cancel: 'Cancel',
            action: '',
          })
        }

        break
      default:
    }
    setShowM(true)
  }

  const handleOk = async e => {
    switch (e.currentTarget.id) {
      case 'folder':
        try {
          await createDir(name)
        } catch (e) {
          console.log(e)
        }
        break
      case 'file':
        try {
          await createFile(name)
        } catch (e) {
          console.log(e)
        }
        break
      case 'addMember':
        try {
          await addMember(name)
        } catch (e) {
          console.log(e)
        }
        break
      case 'deleteMember':
        try {
          await removeMebmer(e.name)
        } catch (e) {
          console.log(e)
        }
        break

      case 'deleteF':
        try {
          await deleteF(path.path)
        } catch (e) {
          console.log(e)
        }
        break

      case 'gitInit':
        try {
          gitInit(name)
        } catch (e) {
          console.log(e)
        }
        break

      case 'gitCommit':
        try {
          gitCommit(name)
        } catch (e) {
          console.log(e)
        }
        break

      case 'gitCreateRepo':
        try {
          gitCreateRepo(name)
        } catch (e) {
          console.log(e)
        }
        break

      case 'gitPush':
        try {
          gitPush(name)
        } catch (e) {
          console.log(e)
        }
        break

      default:
    }
    handleCancel()
  }

  const gitPush = async (commit) => {
    try {
      const gitPush = await request('/api/git/gitPush', 'POST', { roomId: project.roomId, commit }, { authorization: 'Bearer ' + auth.token })
      message(gitPush.message)
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! gitPush')
    }
  }

  const gitCreateRepo = async (name) => {
    try {
      const gitCreateRepo = await request('/api/git/gitCreateRepo', 'POST', { roomId: project.roomId, url: name }, { authorization: 'Bearer ' + auth.token })
      message(gitCreateRepo.message)
      // console.log("commit")
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! gitInit')
    }
  }

  const gitInit = async (name) => {
    try {
      const gitInit = await request('/api/git/gitInit', 'POST', { roomId: project.roomId, commitName: name }, { authorization: 'Bearer ' + auth.token })
      message(gitInit.message)
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! gitInit')
    }
  }

  const gitCommit = async (name) => {
    try {
      const gitAddAll = await request('/api/git/gitAddAll', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      const gitCommit = await request('/api/git/gitCommit', 'POST', { roomId: project.roomId, commitName: name }, { authorization: 'Bearer ' + auth.token })
      message(gitCommit.message)
    } catch (e) {
      console.log(e)
      message('Что-то пошло не так! gitCommit')
    }
  }

  const gitDiff = async () => {
    console.log("GIT DIFF")
    try {
      const data = await request('/api/git/gitDiff', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      setTreeFromGit(data.tree)
    } catch (e) {
      console.log(e)
    }
  }

  const getFileContent = async (path) => {
    try {
      console.log("getFileContent", path)
      const fileName = await request('/api/project/getFileContent', 'POST', { path: path.path, fileName: path.name, roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
      setFileContent(fileName.fileContent)
    } catch (e) {
      console.log(e)
    }
  }



  useEffect(() => {
    if (project?.id) {

      (async () => {
        try {
          const data = await request('/api/git/getCurrentBranch', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
          setCurrentBranch(data.branch)
        } catch (e) {
          console.log(e)
        }
      })();

      (async () => {
        try {
          const data = await request('/api/project/getProjectFiles', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
          setTree(data.tree)
          console.log("TREE", data.tree)
          getProjectMembers()
          getProjectUsersPermission()
        } catch (e) {
          console.log(e)
        }
      })()

    }
  }, [project])

  useEffect(() => {
    socket?.on("updateTreeOnClient", tree => {
      console.log("tree", tree)
      setTree(prev => ({ ...prev, ...tree }))
    })

    socket?.on("changePermissionOnClient", userPermissionInRoom => {
      // console.log("IN MEMBER PERMISSION--", userPermissionInRoom)
    })

  }, [socket])



  useEffect(() => {

    if (auth?.lSidebar === 'git' && currentBranch !== null) {
      (async () => {
        await gitDiff()
      })();
    }

    if (auth?.lSidebar === 'test') {

      (async () => {
        try {
          const data = await request('/api/test/getTests', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
          setTreeFromTest(data.tree)
        } catch (e) {
          console.log(e)
        }
      })();

      (async () => {
        try {
          const data = await request('/api/test/getTestsCreatedFor', 'POST', { roomId: project.roomId }, { authorization: 'Bearer ' + auth.token })
          setTreeFromTestCreatedFor(data.tree)
        } catch (e) {
          console.log(e)
        }
      })();

    }

  }, [auth?.lSidebar])





  function checkAndAdd(obj) {
    var found = tabList.some(function (el) {
      return el.name === obj.name;
    });
    if (!found) {
      setTabList(prev => ([...prev, obj]))
    }
  }


  const fileClicked = async (tPath) => {
    if (tPath.path !== "") {
      console.log("SET IN TAB ARRAY-", tPath)
      // if (!tabList.includes(tPath)) {
      // setTabList(prev => ([...prev, tPath]))
      checkAndAdd(tPath)
      console.log("tabList on fileClicked", tabList)

      // }
      if (!showEditor) setShowEditor(true)
      await getFileContent(tPath)
    }
    setPath(tPath)
    setIsDir(false)
  }

  const dirClicked = (tPath) => {
    setPath(tPath)
    setIsDir(true)
  }

  const tabSizeClickHandler = e => {
    setTabSize(e.currentTarget.id)
  }

  const languageClickHandler = e => {
    setLanguageDescription(e.currentTarget.id)
    // setLanguageName(e.target.attributes.name.value)
    setLanguageName(e.currentTarget.dataset.name)
  }

  const tabSizeContent = (
    <div className="text-sm text-blueGray-300 flex flex-col gap-2 cursor-pointer">
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="1"
        onClick={tabSizeClickHandler}>1</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="2"
        onClick={tabSizeClickHandler}>2</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="3"
        onClick={tabSizeClickHandler}>3</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" id="4"
        onClick={tabSizeClickHandler}>4</p>
    </div>
  )

  const languageContent = (
    <div className="text-sm text-blueGray-300 flex flex-col gap-2 cursor-pointer">
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" data-name="JavaScript" id="text/javascript"
        onClick={languageClickHandler}>JavaScript</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" data-name="HTML" id="text/html"
        onClick={languageClickHandler}>HTML</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" data-name="PHP" id="text/x-php"
        onClick={languageClickHandler}>PHP</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" data-name="C++" id="text/x-c++src"
        onClick={languageClickHandler}>C++</p>
      <p className="leading-[150%] hover:bg-blueGray-300 hover:text-blueGray-700 cursor-pointer px-2" data-name="Python" id="text/x-python"
        onClick={languageClickHandler}>Python</p>
    </div>
  )

  if (!auth.user) {
    return <LoaderFull />
  }

  const listOfMembers = projectMember.map((member, idx) =>
    <li key={idx}><Member id='deleteMember' projectUsersPermission={projectUsersPermission} showModal={showModal} you={auth.user.email} usersOnline={usersOnline}
      name={member[0]} color={member[1]} whoAdmin={project.whoAdmin} youId={auth.user.id} room={project.id} socket={socket} /></li>
  );
  return (
    <HScreen>
      <div className="flex h-full w-full ">
        <div className="w-full h-full max-h-[100vh] flex flex-col justify-between">
          {/*main block*/}
          <div className="flex h-full justify-between bg-[#24292E]">
            {/*left sidebar*/}
            <>
              {auth?.lSidebar === 'filemanger' &&
                <div className="bg-[#1F2428] h-full py-2 px-4 min-w-[230px] basis-[230px] flex-grow-0 flex justify-center noselect reletive">
                  <div className="flex w-full flex-col gap-4 items-center ">
                    <div className='flex w-full justify-between items-center'>
                      <span className='text-blueGray-50 text-sm'>Файлы проекта</span>
                      <div className='flex gap-2'>
                        <DocumentAddIcon className="h-[16px] text-gray-700 cursor-pointer hover:text-gray-400" id='file' onClick={showModal} />
                        <FolderAddIcon className="h-[16px] text-gray-700 cursor-pointer hover:text-gray-400" id='folder' onClick={showModal} />
                        <TrashIcon className='h-[16px] text-gray-700 cursor-pointer hover:text-gray-400' id='deleteF' onClick={showModal} />
                        {/* path.path != "" ? showModal : message('Нечего удалять') */}
                      </div>
                    </div>
                    {loading && <div className=' absolute items-center top-[70px] left-[150px] '>  <InlineLoader /> </div>}
                    {<TreeView fileClicked={fileClicked} dirClicked={dirClicked} tree={tree} project={project} />}

                  </div>
                </div>
              }



              {auth?.lSidebar === 'search' &&
                <div className="bg-[#1F2428] h-full py-2 px-4 min-w-[230px] basis-[230px] flex-grow-0 flex justify-center noselect">
                  <div className="flex w-full flex-col gap-4 items-center">
                    <span className='text-blueGray-50 text-sm'>Поиск по проекту</span>
                    <Search setTreeFromSearch={setTreeFromSearch} />
                    {loading && <div className=' absolute items-center top-[70px] left-[150px] '>  <InlineLoader /> </div>}
                    {<TreeView fileClicked={fileClicked} dirClicked={dirClicked} tree={treeFromSearch} project={project} />}
                  </div>
                </div>
              }



              {auth?.lSidebar === 'git' &&
                <div className="bg-[#1F2428] h-full py-2 px-4 min-w-[230px] basis-[230px] flex-grow-0 flex justify-center noselect">
                  <div className="flex w-full flex-col gap-4 items-center">

                    <div className=' flex'>
                      <span className='text-blueGray-50 text-sm' >Система контроля версий</span>
                      <RefreshIcon className='h-[20px] w-[20px] text-blueGray-400 text-xs hover:text-gray-300 cursor-pointer pl-1'
                        onClick={gitDiff}
                      />
                    </div>
                    {/* <div className='flex gap-2'>
                        <RefreshIcon className="h-[16px] text-gray-700 cursor-pointer" />
                      </div> */}
                    <Git showModal={showModal} roomId={project.roomId} />

                    {loading && <div className=' absolute items-center top-[70px] left-[150px] '>  <InlineLoader /> </div>}


                    {treeFromGit !== null ?
                      <div className='w-full flex flex-col h-full justify-center overflow-auto scrollbar-hide md:scrollbar-default'>
                        <p className='text-blueGray-400'>Изменения</p>
                        <TreeView fileClicked={fileClicked} dirClicked={dirClicked} tree={treeFromGit} project={project} />
                      </div>
                      :
                      <p className='text-[#6A737D]'>Нет изменённых файлов</p>
                    }


                  </div>
                </div>
              }
              {auth?.lSidebar === 'test' &&
                <div className="bg-[#1F2428] h-full py-2 px-4 min-w-[230px] basis-[230px] flex-grow-0 flex justify-center noselect">
                  <div className="flex w-full flex-col gap-4 items-center">
                    <div className='flex w-full justify-between items-center'>
                      <span className='text-blueGray-50 text-sm'>Модуль тестирования</span>
                      <div className='flex gap-2'>
                        <PlayIcon className="h-[16px] text-gray-700 cursor-pointer" />
                      </div>
                    </div>

                    {loading && <div className=' absolute items-center top-[70px] left-[150px] '>  <InlineLoader /> </div>}
                    {treeFromTest !== null ?
                      <div className='w-full'>
                        <TreeView fileClicked={fileClicked} dirClicked={dirClicked} tree={treeFromTest} project={project} test={true} />
                        <p className='font-normal text-gray-400'>Файлы автоматического тестирования сформированы для:</p>
                        <TreeView fileClicked={fileClicked} dirClicked={dirClicked} tree={treeFromTestCreatedFor} project={project} exlamination={true} />
                      </div>
                      :
                      <></>
                    }
                  </div>
                </div>
              }
            </>

            {/*editor*/}
            <div className="flex-1 h-full w-full">
              {showEditor && tabList.length ?
                <div className='flex flex-col h-full'>


                  <Tabs tabList={tabList} setTabList={setTabList} usersInTab={usersInTab} onTabClick={fileClicked} tabActive={path} />

                
                    <Editor setCodeForCheck={setCodeForCheck} socket={socket} setUsersInTab={setUsersInTab}
                      projectUsersPermission={projectUsersPermission} whoAdmin={project.whoAdmin}
                      language={languageDescription} userColor={auth.user.color} tabSize={tabSize}
                      setCursor={setCursor} fileContent={fileContent} path={path} />
           
                  <div className=' flex flex-col h-[200px] max-h-[200px] w-full '>
                   <Problem languageName={languageName} codeForCheck={codeForCheck} path={path} />
                  </div>
                </div>
                :
                <div className='flex justify-center mt-[25%] w-full '>
                  <PageTitle title="AFIR Text Editor" subTitle="the new way of code development" />
                </div>
              }
            </div>

          </div>

          {/*bottom info*/}


          <div className="w-[calc(100%-380px)] h-[28px] bg-[#24292E] text-blueGray-400 text-xs flex absolute bottom-0 items-center justify-between px-4">
            <div className='flex gap-2'>
              <div className='flex gap-1 items-center'>
                <ShareIcon className="h-[13px]" />
                <span className='text-blueGray-400 text-xs'>{currentBranch}</span>
              </div>
            </div>
            <div className='flex gap-3'>
              <span className='text-blueGray-400 text-xs'>Ln: {cursor.ln} Col: {cursor.col}</span>

              {/*Tab size poopover*/}
              <Popover
                //  title={'TabSize'}
                content={tabSizeContent}
                trigger="click"
              // open={popoverClicked}
              // onOpenChange={handleClickChange}
              >
                <span className='text-blueGray-400 text-xs hover:text-gray-300 cursor-pointer'>TabSize: {tabSize}</span>
              </Popover>

              {/* <input className='inputBackColor' size={2} value= onChange={changeTabSizeHandler} /> */}
              <span className='text-blueGray-400 text-xs'>UTF-8</span>
              <span className='text-blueGray-400 text-xs'>LF</span>
              <span className='text-blueGray-400 text-xs'>



                {/*Language popover*/}
                <Popover
                  //  title={'TabSize'}
                  content={languageContent}
                  trigger="click"
                // open={popoverClicked}
                // onOpenChange={handleClickChange}
                >
                  <span className='text-blueGray-400 text-xs hover:text-gray-300 cursor-pointer'>{languageName}</span>
                </Popover>


              </span>

            </div>
          </div>
        </div>

        {/*right sidebar*/}


        <div className=" max-h-[100vh] h-full bg-[#1F2428] py-2 min-w-[320px] basis-[320px] z-20">
          <div className='max-h-1/2 flex-shrink-0 h-1/2'>
            <p className="text-sky-50 px-4 text-sm py-2">Участники</p>
            <ul>{listOfMembers}</ul>
            <div className='flex items-center text-gray-600 cursor-pointer hover:text-gray-400 gap-1' id='addMember' onClick={showModal}>
              <span className='w-1 h-8 bg-gray-800'></span>
              <PlusIcon className='h-4 w-4 ' />
              <p className=" text-s ml-1.5 ">Пригласить участника</p>
            </div>
            <div className='absolute bottom-1/2'>
              <div className='h-0.5 w-[320px] bg-gray-800'></div>
              <p className="text-sky-50 px-4 text-sm py-2">Чат</p>
            </div>
          </div>


          <div className='max-h-1/2  h-1/2 w-full overflow-auto scrollbar-hide md:scrollbar-default'>

            <Chat you={auth.user.email} color={auth.user.color} project={project} setUsersOnline={setUsersOnline} setSocket={setSocket} />
          </div>

        </div>
      </div>

      <Modal
        open={showM}
        title={null}
        footer={null}
        // centered
        onCancel={handleCancel}
      >
        <div className='flex flex-col gap-5'>
          <p className="text-2xl block text-blueGray-300 mb-2">{modal.title}</p>

          {modal.placeholder === "" ?
            null
            :
            <InputModal
              addonBefore={modal.addonBefore}
              addonAfter={modal.addonAfter}
              className="bg-coolGray-600 text-blueGray-300"
              placeholder={modal.placeholder}
              id="name"
              name="name"
              value={name}
              autoComplete="off"
              onChange={e => { setName(e.target.value) }}
            />
          }

          <div className='flex justify-between items-center gap-4'>
            <Button
              id={modal.action}
              size="md"
              className="w-full"
              onClick={handleOk}
            >
              {modal.ok}
            </Button>
            <Button
              size="md"
              color="grey"
              className="w-full"
              onClick={handleCancel}
            >
              {modal.cancel}
            </Button>
          </div>
        </div>
      </Modal>
    </HScreen>
  )
}