import React , { useEffect, useRef, useState } from 'react'


export const ChatMessage = ({ name, message, color, time, you, usersInRoomChat }) => {
    const messagesEndRef = useRef(null)

    useEffect(() => {
        scrollToBottom()
      }, [message])
    

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    
      


    return (
        <div className='py-2 px-4'>
            <div className='flex items-center gap-2'>
                <div className="rounded-full w-2 h-2 order-1" style={usersInRoomChat.find(userInRoomChat => userInRoomChat.name === name) !== undefined ? { background: "#65A30D" } : { background: "#A3A3A3" }}></div>
                <p className='order-2 text-orange-400' >{you === name ? "Вы" : name}</p> <span className='order-3 text-gray-600'>{time}</span>
            </div>
            
            <p className='flex-wrap'>{message}</p>
            <div ref={messagesEndRef}/>
        </div>
    )
}