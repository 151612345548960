import React, { useEffect, useState } from 'react'
import TreeNode from './TreeNode'

export const TreeView = ({ fileClicked = () => { }, dirClicked = () => { }, tree, project = null, test = false, exlamination = false }) => {
  const [currentPath, setCurrentPath] = useState({path: ''})

// console.log("test trview", test)
  useEffect(() => {
    if (!currentPath.children) {
      fileClicked(currentPath)
    } else {
      dirClicked(currentPath)
    }
  }, [currentPath])

  return (
    <div className='h-full w-full overflow-auto whitespace-nowrap noselect treeScroller'>
      <>
        {typeof tree === 'object' ?
          <TreeNode node={tree} level={0} currentPath={currentPath} setCurrentPath={setCurrentPath} project={project} test={test} exlamination={exlamination} /> : <span className='text-[#6A737D]'>{tree}</span>}
      </>
    </div>
  )
}