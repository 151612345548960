import { Button as AntButton, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'

import { arrayOf, bool, element, node, oneOf, oneOfType, string, func } from 'prop-types'
const antLoaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const Button = ({ loading, children, icon, color, className, size, ...props }) => {

  let classNames = ''
  switch (size) {
    case 'none':
      classNames = ' '
      break;
    case 'xs':
      classNames = ' py-2 px-4 fs-sm rounded-sm '
      break;
    case 'lg':
      classNames = ' py-3 px-6 fs-lg font-medium rounded-md '
      break;
    case 'xl':
      classNames = ' py-4 px-8 fs-xl font-medium rounded-lg '
      break;
    default: // md
      classNames = 'py-3 px-4 text-sm rounded-md '
      break;
  }

  switch (color) {
    case 'purple':
      classNames += ` 
        bg-violet-500 
        hover:bg-violet-600 
        active:bg-violet-700 
        focus:outline-none 
        focus:bg-violet-600 
        
        text-white 
        hover:text-white 
        focus:text-white 
      `
      break;
    case 'secondary':
      classNames += ` 
        bg-white
        text-blue-700
        border-blue-700

        hover:bg-func-hover
        hover:text-blue-700

        focus:outline-none 
        focus:text-blue-700
        focus:border-blue-700
      `
      break;
    case 'ghost':
      classNames += ` 
        bg-white
        text-char-base
        border-0

        hover:bg-func-hover
        hover:text-blue-700

        focus:outline-none 
        focus:text-blue-700
      `
      break;
    case 'grey':
      classNames += ` 
        bg-[#1F2428]
        text-coolGray-500
        border
        border-coolGray-500

        hover:bg-coolGray-600
        hover:text-white
        hover:border-coolGray-500

        focus:outline-none 
        focus:text-blue-700
      `
      break;
    case 'danger':
      classNames += ` 
        bg-red-700 
        hover:bg-red-800 
        active:bg-red-800 
        focus:outline-none 
        focus:bg-red-800 
        
        border-red-900
        hover:border-red-900
        focus:border-red-900

        text-white 
        hover:text-white 
        focus:text-white 
      `
      break;
    case 'accent':
      classNames += ` 
        bg-orange-400 
        hover:bg-orange-500 
        active:bg-orange-600 
        focus:outline-none 
        focus:bg-orange-500 
        
        border-orange-500
        hover:border-orange-600
        focus:border-orange-600

        text-white 
        hover:text-white 
        focus:text-white 
      `
      break;
    case 'gradient':
      classNames += ` 
        bgGradient 
        text-char-base
        lightBlue800
        border-gray-300
        hover:bg-func-hover
        hover:text-blue-700

        focus:outline-none 
        focus:text-blue-700
      `
      break;
    default: //case 'primary':
      classNames += ` 
        bg-[#52A4EA] 
        hover:bg-blue-800 
        active:bg-blue-900 
        focus:outline-none 
        focus:bg-blue-800 
        
        border-1
        border-transparent
        
        hover:border-transparent
        
        text-white 
        hover:text-white 
        focus:text-white 
      `
      break;
  }

  return (
    <AntButton
      key={size}
      loading={loading}
      className={`
        h-max 
        active:scale-95 
        ${classNames} 
        ${className || ''}
      `}
      {...props}
    >
      {children}
      {loading && <Spin indicator={antLoaderIcon} />}
    </AntButton>
  )
}

Button.propTypes = {
  size: oneOf(['none', 'xs', 'md', 'lg', 'xl']),
  color: oneOf(['primary', 'secondary', 'accent', 'danger', 'grey', 'ghost', 'gradient']),
  loading: bool,
  disabled: bool,
  className: string,
  onClick: func,
  children: oneOfType([
    arrayOf(node), node
  ])
}

Button.defaultProps = {
  size: 'lg',
  color: 'primary',
  loading: false,
  disabled: false,
}
