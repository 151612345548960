import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import { Button } from '../../components/Button'


export const AuthPage = ({ isLogin = true }) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const message = useMessage()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', 'POST', { ...form })
      message(data.message)
    } catch (e) {}
  }

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', { ...form })
      message(data.message)
      dispatch(login({ token: data.token, user: data.user }))
      location.state?.from?.pathname ? history(location.state?.from?.pathname) : history('/')
    } catch (e) {}
  }

  return (
    <div className="h-[100vh] w-[100vw] flex justify-center items-center bg-[#101214]">
      <div className="flex flex-col bg-[#1F2428] w-[473px] gap-5 px-9 py-8 rounded">
        <p className="text-2xl block text-blueGray-300 mb-2">{isLogin ? `Войти` : `Зарегистрироваться`}</p>

        {/*Form*/}
        <Input
          className="bg-coolGray-600 text-blueGray-300"
          placeholder="Введите email"
          id="email"
          name="email"
          value={form.email}
          autoComplete="off"
          onChange={changeHandler}
        />
        <Input
          className="bg-coolGray-600 text-blueGray-300"
          placeholder="Введите пароль"
          type="password"
          id="password"
          name="password"
          value={form.password}
          onChange={changeHandler}
        />

        {/*Buttons*/}
        <div className="flex justify-between items-center gap-4">
          {isLogin ?
            <>
              <Button
                size="md"
                className="w-full"
                onClick={loginHandler}
                disabled={loading}
              >
                Войти
              </Button>
              <Button
                size="md"
                color="grey"
                className="w-full"
                onClick={registerHandler}
                disabled={loading}
              >
                Зарегистрироваться
              </Button>
            </>
            :
            <>
              <Button
                size="md"
                className="w-full"
                onClick={registerHandler}
                disabled={loading}
              >
                Зарегистрироваться
              </Button>
              <Button
                size="md"
                color="grey"
                className="w-full"
                onClick={loginHandler}
                disabled={loading}
              >
                Войти
              </Button>
            </>
          }
        </div>
      </div>
    </div>
  )
}
