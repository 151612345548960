import { AUTH_LOGIN, AUTH_LOGOUT, LSIDEBAR_CHANGE, PROJECT_SETCURRENT, PROJECT_UPDATELIST } from '../types/types'

const initialState = {
  token: null,
  userId: null,
  user: null,
  isAuthenticated: false,
  selectedProject: null,
  lSidebar: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      const data = {...initialState, ...action.payload, isAuthenticated: true, userId: action.payload.user.id}
      return { ...state,  ...data}
    case AUTH_LOGOUT:
      return { ...state,  ...initialState}
    case PROJECT_SETCURRENT:
      return { ...state, selectedProject: action.payload }
    case PROJECT_UPDATELIST:
      let user = state.user
      user.projects = action.payload
      return { ...state, user }
    case LSIDEBAR_CHANGE:
      return { ...state, lSidebar: action.payload }
    default:
      return state
  }
}