import React, { useState } from 'react'


import {
    PaperAirplaneIcon
} from '@heroicons/react/outline'


export const ChatInput = ({ ws, onSubmitMessage }) => {
    const [message, setMessage] = useState("")
    return (
        <form
            action="."
            onSubmit={e => {
                e.preventDefault()
                onSubmitMessage(message)
                setMessage('')
            }}
        >

            <input className='rounded-sm absolute bottom-0 bg-[#4B5563] text-gray-100 flex flex-row items-center p-2 gap-5 w-[320px] h-9'
                type="text"
                placeholder={'Ваше сообщение'}
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <button type="submit">
                <PaperAirplaneIcon className=' absolute bottom-[8px] right-[-310px] rotate-90 w-[18px] text-gray-400 cursor-pointer hover:text-gray-100' />
            </button>
        </form >
    )
}


